import Head from 'next/head';

export const Schema = ({ schema }: { schema: object }) => {
  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(schema),
        }}
        type="application/ld+json"
      />
    </Head>
  );
};
