import { Text } from 'components/UI/Typography/Text';

import styles from './WeareuscFooter.module.scss';

export const WeareuscFooter = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <Text className={styles.heading} variant="h3">
          Garry Paskwietz (1966-2019)
        </Text>
        <div className={styles.flex}>
          <Text className={styles.body} variant="body1">
            Garry Paskwietz was a pioneer in bringing team-specific sports
            coverage to the internet. He founded WeAreSC in 1998 and was an Emmy
            winner for his work with Fox Sports West. Always generous with his
            time, knowledge, and compassion, he touched the lives of countless
            USC and high school football fans.
          </Text>
          <img
            alt="Garry Paskwietz"
            className={styles.garryimage}
            height="116"
            src="https://on3static.com/static/on3/garry-paskwietz.jpg"
            width="100"
          />
        </div>
      </div>
    </div>
  );
};
