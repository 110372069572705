import { On3IconLogoWhite, On3IconSpinyLogo } from '@on3/icons';
import clsx from 'clsx';
import Link from 'next/link';

import { about, socialConnect, support } from './helper';
import styles from './NetworkFooter.module.scss';

type INetworkFooterProps = React.HTMLProps<HTMLElement>;

export const NetworkFooter = ({
  className,
  ...footerProps
}: INetworkFooterProps) => {
  return (
    <footer className={clsx(className, styles.block)} {...footerProps}>
      <div className={styles.inner}>
        <div className={styles.menu}>
          <div className={styles.logo}>
            <Link href="/">
              <On3IconLogoWhite height={44} width={100} />
              <span className="sr-only">Go to On3 Home</span>
            </Link>
          </div>

          <div className={styles.links}>
            <span className={styles.title}>About On3</span>
            {about.map((item) => (
              <Link href={item.link} key={item.key}>
                {item.name}
              </Link>
            ))}
          </div>

          <div className={styles.links}>
            <span className={styles.title}>Support</span>
            {support.map((item) => (
              <Link
                href={item.link}
                key={item.key}
                rel={item.rel}
                target={item.target}
              >
                {item.name}
              </Link>
            ))}
          </div>

          <div className={styles.links}>
            <span className={styles.title}>On3 Connect</span>
            {socialConnect.map((item) => (
              <Link
                className={styles.social}
                href={item.link}
                key={item.key}
                rel="noopener noreferrer"
                target="_blank"
              >
                {item.icon}
                <span>{item.name}</span>
              </Link>
            ))}
          </div>

          <div className={styles.badges}>
            <div>
              <span className={styles.title}>Publishing Data Powered by</span>
              <a
                href="https://spiny.ai/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <On3IconSpinyLogo height={36} width={110} />
                <span className="sr-only">Spiny AI</span>
              </a>
            </div>
            <div>
              <span className={styles.title}>
                The On3 App for college sports fans:
              </span>
              <div className={styles.downloads}>
                <a
                  href="https://apps.apple.com/us/app/on3-app/id1629727374"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    alt="Apple Store Logo"
                    height={36}
                    src="https://on3static.com/static/on3/apple-store.png"
                    width={125}
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.on3"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    alt="Google Play Logo"
                    height={36}
                    src="https://on3static.com/static/on3/google-play.png"
                    width={125}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <p className={styles.copyright}>
          © {new Date().getFullYear()} On3 Media, Inc. All rights reserved. On3
          is a registered trademark of On3 Media, Inc.
        </p>
      </div>
    </footer>
  );
};
