import { SVGProps } from 'react';

const SvgLogoWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 100 45"
    fill="none"
    {...props}
  >
    <path
      d="M30.287 29.454c-.522 1.675-1.306 3.086-2.437 4.232-1.132 1.147-2.524 2.117-4.178 2.734-1.74.617-3.742.97-6.092.97h-4.09c-2.35 0-4.352-.353-6.092-.97-1.741-.617-3.133-1.587-4.178-2.734C2.09 32.54 1.305 31.13.783 29.454.261 27.778 0 25.926 0 23.898V18.96c0-2.117.261-3.969.783-5.644.522-1.676 1.306-3.087 2.437-4.321C4.352 7.848 5.744 6.878 7.398 6.26c1.74-.617 3.742-.97 6.092-.97h4.09c2.35 0 4.352.353 6.092.97 1.741.617 3.133 1.587 4.178 2.734 1.131 1.146 1.915 2.645 2.437 4.32.522 1.676.783 3.528.783 5.645v4.938c.087 2.028-.174 3.88-.783 5.556ZM6.962 31.217c1.219 1.411 3.22 2.117 6.006 2.117h5.308c2.785 0 4.787-.706 6.006-2.117 1.218-1.41 1.74-3.439 1.74-6.26v-7.232c0-2.822-.609-4.85-1.74-6.261-1.219-1.411-3.22-2.028-6.006-2.028h-5.308c-2.785 0-4.787.705-6.006 2.028-1.218 1.41-1.827 3.44-1.827 6.261v7.231c0 2.822.609 4.939 1.827 6.261ZM58.31 38.008c-1.305 0-2.35-.353-3.22-1.059-.783-.705-1.479-1.587-2.175-2.645L42.384 17.108c-.174-.353-.348-.617-.522-.794-.087-.088-.087-.088-.174-.088-.174 0-.261.088-.261.088-.087.088-.087.353-.087.617v20.636h-4.787v-20.46c0-1.498.348-2.821 1.131-3.88.784-1.146 2.09-1.675 3.83-1.675 1.218 0 2.262.353 3.133 1.147.783.705 1.566 1.675 2.35 2.998l10.27 16.931c.173.265.347.53.521.618.088.088.175.176.262.176.174 0 .174-.088.26-.088.088-.088.088-.265.088-.441v-20.9h4.7v20.724c0 1.587-.436 2.821-1.219 3.791-.783.97-2.002 1.5-3.568 1.5ZM91.644 44.269h-14.1c-4.7 0-8.441-3.88-8.441-8.554V8.554C69.103 3.792 72.932 0 77.545 0h14.012c4.7 0 8.442 3.88 8.442 8.554v27.073c.087 4.761-3.743 8.642-8.355 8.642Z"
      fill="#fff"
    />
    <path
      d="M85.464 38.007H75.195v-6.35h10.53c1.045-.175 1.828-.88 2.002-1.763 0-.176.087-.352.087-.44 0-2.47-4.438-4.145-6.266-5.82V20.81c1.828-1.675 6.266-3.527 6.266-5.82 0-.176 0-.353-.087-.44-.174-.883-.957-1.676-1.915-1.764H75.195V6.349h10.792c3.742.265 6.962 2.998 7.658 6.614.087.529.174 1.058.174 1.675 0 2.822-1.392 5.38-3.742 6.879l-.783.617.783.617c2.35 1.588 3.742 4.145 3.742 6.879 0 .529-.087 1.146-.174 1.675-.783 3.616-3.916 6.35-7.658 6.614-.087.088-.349.088-.523.088Z"
      fill="#1D252C"
    />
  </svg>
);

export default SvgLogoWhite;
