import { SocialIcon } from '@on3/ui-lib/components/SocialIcon';

export const about = [
  {
    key: 1,
    link: '/about/',
    name: 'About',
  },
  {
    key: 2,
    link: '/page/advertise/',
    name: 'Advertisers',
  },
  {
    key: 3,
    link: '/page/careers/',
    name: 'Careers',
  },
  {
    key: 4,
    link: '/contact/',
    name: 'Contact',
  },
];

export const support = [
  {
    key: 1,
    link: 'https://on3.zendesk.com/hc/en-us',
    rel: 'noopener noreferrer',
    target: '_blank',
    name: 'Customer Service',
  },
  {
    key: 3,
    link: '/page/privacy-policy/',
    rel: 'strict-origin-when-cross-origin',
    target: '_self',
    name: 'Privacy Policy',
  },
  {
    key: 4,
    link: '/page/terms-of-service/',
    rel: 'strict-origin-when-cross-origin',
    target: '_self',
    name: 'Terms of Service',
  },
];

export const socialConnect = [
  {
    key: 1,
    link: 'https://twitter.com/on3sports',
    icon: <SocialIcon type="twitter" />,
    name: 'Twitter',
  },
  {
    key: 2,
    link: 'https://www.facebook.com/On3Sports',
    icon: <SocialIcon type="facebook" />,
    name: 'Facebook',
  },
  {
    key: 3,
    link: 'https://instagram.com/on3/',
    icon: <SocialIcon type="instagram" />,
    name: 'Instagram',
  },
];
