import type { HeadlineResponse, SiteCategoryResponse } from '@on3/api';
import { ArticleProvider, useAuth } from '@on3/ui-lib';
import { ISiteContext, SiteProvider } from '@on3/ui-lib/contexts/SiteProvider';
import clsx from 'clsx';
import { Billboard } from 'components/Ads/Billboard';
import { MobileBanner } from 'components/Ads/MobileBanner';
import { Adblock } from 'components/Analytics/Adblock';
import { Comscore } from 'components/Analytics/Comscore';
import { Piano } from 'components/Analytics/Piano';
import { Quantcast } from 'components/Analytics/Quantcast';
import { Sailthru } from 'components/Analytics/Sailthru';
import { Spiny } from 'components/Analytics/Spiny';
import { NetworkFooter } from 'components/Footer/NetworkFooter';
import { WeareuscFooter } from 'components/Footer/WeareuscFooter';
import { Header } from 'components/Header';
import { Schema } from 'components/Schema/Schema';
import { getDay } from 'date-fns';
import Head from 'next/head';
import { useRouter } from 'next/router';

import styles from './PageWrapper.module.scss';

interface IPageWrapperProps {
  title?: string;
  description?: string;
  image?: OGImage;
  ogType?: string;
  canonical?: string | null;
  /** For pages that don't show ads, we need to not load the adhesion. */
  hideAds?: boolean;
  children: React.ReactNode;
  noIndex?: boolean;
  siteData?: ISiteContext;
  pageType?: string;
  alternateBackground?: boolean;
  article?: HeadlineResponse;
  category?: SiteCategoryResponse;
  schema?: object;
}
interface OGImage {
  src?: string;
  height?: number;
  width?: number;
}

const noHeight = { height: '0' };

export const PageWrapper = ({
  title,
  children,
  description,
  image,
  ogType,
  hideAds,
  canonical,
  noIndex = false,
  siteData,
  alternateBackground,
  pageType,
  ...props
}: IPageWrapperProps) => {
  const { currentSite } = siteData || {};

  const router = useRouter();

  const isHome = pageType === 'home';
  const isArticle = pageType === 'article';

  const { user } = useAuth();
  const showAdhesion = !isArticle || (isArticle && !user?.has);
  const adhesionUnit = user?.has
    ? 'mobile_adhesion_top'
    : 'mobile_adhesion_top_guest';

  if (!image?.src && !isArticle) {
    image = {
      src: 'https://on3static.com/cdn-cgi/image/height=627,width=1200,quality=95,fit=cover/static/on3/on3-share.jpeg',
      height: 627,
      width: 1200,
    };
  }

  // We only want this to run on fridays
  const now = new Date();
  const centralTimeOffset = -6;
  const nowCentralTime = new Date(
    now.setHours(now.getHours() + centralTimeOffset),
  );
  const isFriday = getDay(nowCentralTime) === 5;
  const isSaturday = getDay(nowCentralTime) === 6;
  const isNil = currentSite?.key === 368;
  const isOn3 = currentSite?.key === 44;
  const isTeam = currentSite?.isTeam;
  const showNilBillboard = isFriday && isNil && (isHome || isArticle);
  const showBillboard = isSaturday && (isOn3 || isTeam) && isHome;

  return (
    <SiteProvider
      currentSite={siteData?.currentSite}
      defaultYears={siteData?.defaultYears}
      menuData={siteData?.menuData}
      pageType={pageType}
      secondaryMenuData={siteData?.secondaryMenuData}
      siteUrls={siteData?.siteUrls}
      sportsData={siteData?.sportsData}
      subConfig={siteData?.subConfig}
    >
      <ArticleProvider {...props}>
        {props.schema && <Schema schema={props.schema} />}
        <Head>
          {title && (
            <>
              <title key="title">{title}</title>
              <meta content={title} key="ogTitle" property="og:title" />
            </>
          )}
          {description && (
            <>
              <meta content={description} name="description" />
              <meta
                content={description}
                key={description}
                property="og:description"
              />
            </>
          )}
          {image && (
            <>
              <meta content={image?.src} key={image?.src} property="og:image" />
              <meta content={`${image?.width}`} property="og:image:width" />
              <meta content={`${image?.height}`} property="og:image:height" />
              <meta content={image?.src} property="cXenseParse:image" />
            </>
          )}

          {ogType && <meta content={ogType} property="og:type" />}
          {!isArticle && !ogType && (
            <meta content="website" property="og:type" />
          )}
          {canonical && <link href={canonical} rel="canonical" />}
          {canonical && <meta content={canonical} property="og:url" />}
          {noIndex && <meta content="noindex" name="robots" />}
        </Head>

        <Quantcast key={`quantcast-${router.asPath}`} />
        <Spiny key={`spiny-${router.asPath}`} />
        <Comscore key={`comscore-${router.asPath}`} />
        <Piano key={`piano-${router.asPath}`} />
        <Sailthru key={`sailthru-${router.asPath}`} />
        <Adblock key={`adblock-${router.asPath}`} />

        {/* Provide an accessible way to skip navigation */}
        <a
          className={styles.skipToContent}
          href="#to_content"
          id="skipToContent"
        >
          Skip to main content
        </a>
        <div className={styles.body}>
          <Header />
          <div id="to_content" />
          {(showNilBillboard || showBillboard) && <Billboard id="leader_top" />}
          <section
            className={clsx(styles.central, {
              [styles.alternateBackground]: alternateBackground,
            })}
          >
            {children}
          </section>
          {currentSite?.key === 79 && <WeareuscFooter />}
          <NetworkFooter />

          {!user?.st?.includes('premium') && !hideAds && (
            <>
              {showAdhesion && (
                <div className={clsx(styles.adhesion, 'adhesionAd')}>
                  <MobileBanner id={adhesionUnit} />
                </div>
              )}
              <div id="image_top" style={noHeight} />
              <div id="home_adhesion" style={noHeight} />
              <div id="OOP_1" style={noHeight} />
              <div id="desktop_adhesion" style={noHeight} />
            </>
          )}
        </div>
        <div className={styles.pianoBottom} id="pianoRegWall" />
        <div className={styles.blocker} id="blocker" />
      </ArticleProvider>
    </SiteProvider>
  );
};
